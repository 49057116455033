<template>
  <div class="wrap bgw">
    <div class="top">
      <img class="ffl" :src="userList.avatar" alt />
      <p>
        充值账户：
        <span>{{userList.user_nickname}}</span>
      </p>
      <div class="fr">
        账户余额：
        <span>{{userList.balance}}</span>
      </div>
    </div>
    <div class="box">
      <div class="list">
        <span
          @click="moneyState = v"
          :class="{moneyActive : moneyState == v}"
          v-for="(v,i) in moneyList"
          :key="i"
        >￥ {{v}}</span>
      </div>
      <div class="list">
        <span @click="moneyState = -1" :class="{moneyActive : moneyState == -1}">其他金额</span>
      </div>
      <div class="type">
        <div class="fl">充值方式:</div>
        <!-- 微信支付 -->
        <div @click="type ='wechatScan'" :class="{typeActive : type == 'wechatScan'}"   class="fl">
          <span></span>
          <img src="@/assets/image/payment_weixin_img@2x.png" alt />
        </div>
        <!-- 支付宝支付 -->
        <div @click="type ='alipayScan'" :class="{typeActive : type == 'alipayScan'}"  class="fl">
          <span></span>
          <img src="@/assets/image/payment_zhifubao_img@2x.png" alt />
        </div>
        <!-- 银联支付 -->
        <div @click="type ='bankpay'" :class="{typeActive : type == 'bankpay'}" class="fl">
          <span></span>
          <img src="@/assets/image/payment_yinlian_img@2x.png" alt />
        </div>
      </div>
      <div class="type moneySum">
        <div class="fl fll">应付金额</div>
        <div v-if="moneyState!= -1" class="fl flll">{{moneyState}} 元</div>
        <div v-if="moneyState == -1" class="fl flll">
          <input
            type="numer"
            v-model="value"
            step="1"
            min="0"
            onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
          /> 元
        </div>
      </div>
      <button @click="checkeing()">确认充值</button>
    </div>
  </div>
</template>
<script>
import { recharge } from "@/request/index";
// abcabcabc
// abcabcabe
export default {
  props: ["userList"],
  data() {
    return {
      state: 0,
      moneyState: 10,
      moneyList: [10, 20, 50, 100, 300],
      value: 0, // 金额
      type: "wechatScan"
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
  },
  methods: {
    checkeing() {
      let money = 0;
      if (this.moneyState != -1) {
        money = this.moneyState;
      } else {
        money = this.value;
      }
      if (money > 0) {
        this.send();
      } else {
        this.$message.error("充值金额请大于0元");
      }
    },
    send() {
      let money = 0;
      if (this.moneyState != -1) {
        money = this.moneyState;
      } else {
        money = this.value;
      }
      recharge({
        token: this.$tokens,
        money: money
      }).then(res => {
        if (res.code == 1) {
          if (this.state == 0) {
            let Athis = this;
            setTimeout(function() {
              let msg = `/user/wallet/pay?money=${money}&out_trade_no=${res.data.out_trade_no}&pay_type=${Athis.type}&state=0`;
              Athis.$router.replace({ path: msg });
            }, 1000);
          }
          this.state = 1;
          this.$message({
            message: "订单创建成功，正在前往支付",
            type: "success"
          });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
input {
  width: 100px;
  text-align: center;
  color: red;
  font-size: 20px;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
button {
  margin-top: 20px;
  width: 130px;
  height: 46px;
  font-size: 18px;
}
.moneySum {
  .fll {
  }
  .flll {
    color: #ff0303;
    padding-left: 0 !important;
  }
}
.typeActive {
  span {
    border: 1px solid #4eeec8 !important;
    background: #4eeec8;
  }
}
.moneyActive {
  border: 2px solid #4eeec8 !important;
}
.type {
  margin-top: 20px;
  overflow: hidden;
  height: 50px;
  line-height: 50px;
  text-align: left;
  div:nth-of-type(1) {
    margin-left: 0;
    width: 80px;
    padding-left: 0;
  }
  > div {
    position: relative;
    padding-left: 40px;
    margin-right: 20px;
    // margin-right: 40px; border:1px solid red;
  }
  span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(230, 230, 230, 1);
    border-radius: 50%;
    transform: translate(0, -50%);
  }
  img {
    vertical-align: middle;
  }
}
.list {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  span {
    width: 158px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    display: inline-block;
    border: 2px solid #bfbfbf;
  }
}
.box {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 40px;
}
.top {
  height: 182px;
  line-height: 182px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  border-bottom: 1px solid #e6e6e6;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    vertical-align: middle;
  }
  p {
    display: inline-block;
    margin-left: 36px;
  }
  span {
    color: #4eeec8;
  }
}
</style>