<template>
  <div class="wrap bgw box">
    <div v-if="state == 0" class="title">银行卡管理</div>
    <div v-if="state == 1" class="title">银行卡添加</div>
    <div v-if="state == 0" class="list">
      <div v-for="(v,i) in cardArrs" :key="i">
        <p class="cardTitle">
          <img src="@/assets/image/payment_yinlian_img@2x.png" alt />
          <span>储蓄卡</span>
        </p>
        <p class="cardNum">
          <span>****</span>
          <span>****</span>
          <span>****</span>
          {{v.numsss}}
          <!-- {{v.account[-3]}}{{v.account[-2]}}  {{v.account[1]}} -->
        </p>
        <p class="delect" @click="delCard(v)">删除</p>
      </div>
      <div v-if="cardArrs.length == 0" @click="state = 1" class="add">
        <p>添加银行卡</p>
      </div>
    </div>
    <div v-if="state == 1" class="addcard">
      <div>
        持卡人
        <input v-model="realname" type="text" maxlength="12" />
      </div>
      <div>
        身份证号
        <input v-model="idcard_no" type="text" maxlength="18" />
      </div>
      <div>
        银行卡号
        <input v-model="account" type="text" maxlength="19" />
      </div>
      <div>
        开户行
        <input v-model="bank_name" type="text" maxlength="20" />
      </div>
      <div>
        手机号
        <input v-model="phone" type="text" maxlength="11" />
      </div>
      <div class="checking">
        验证码
        <input v-model="phoneCode" type="text" maxlength="6" />
        <p @click="phoneChecking" v-if="time == 60 ">获取验证</p>
        <p v-if="time != 60 ">{{time}}</p>
      </div>
      <div>
        <button @click="checking()">绑定</button>
      </div>
    </div>
  </div>
</template>
<script>
import { sendSms } from "@/request/public"; //手机验证
import { add } from "@/request/user"; // 新增 银行卡
import { deletes } from "@/request/user"; //删除银行卡
import { getListCard } from "@/request/user";
export default {
  data() {
    return {
      state: 0, // 列表和  添加银行卡 切换
      phone: "", //手机号
      phoneCode: "", //手机号验证码
      checking1: 1, //验证
      time: 60, //倒计时
      realname: "", //姓名
      idcard_no: "", //身份证号
      account: "", //卡号
      bank_name: "", //开户行
      cardArrs: []
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }

    let num = this.cardArrs;
    if (num[0]) {
      let a = num[0].account;
      this.cardArrs[0].numsss = a.substr(a.length - 4);
    }
    this.start();
  },
  methods: {
    start() {
      getListCard({ token: this.$token }).then(res => {
        if (res.code == 1) {
            this.cardArrs = res.data;
        }
      });
    },
    checking() {
      if (this.realname == "") {
        this.$message.error("请输入 持卡人的姓名");
      } else if (this.idcard_no == "") {
        this.$message.error("请输入 身份证号");
      } else if (this.account == "") {
        this.$message.error("请输入 卡号");
      } else if (this.bank_name == "") {
        this.$message.error("请输入 开户行");
      } else if (this.phone == "") {
        this.$message.error("请输入手机号");
      } else if (this.phoneCode == "") {
        this.$message.error("请输入手机号验证码");
      } else {
        this.send();
      }
    },
    send() {
      add({
        token: this.$tokens,
        realname: this.realname,
        idcard_no: this.idcard_no,
        account: this.account,
        bank_name: this.bank_name,
        mobile: this.phone,
        code: this.phoneCode
      }).then(res => {
        console.log(res);

        if (res.code == 1) {
          this.$message({
            message: "绑定成功",
            type: "success"
          });
        }
        this.$emit("index");
      });
    },
    // 手机号验证
    phoneChecking() {
      if (this.phone == "") {
        this.$message.error("手机号不能为空");
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$message.error("手机号格式不正确");
        return false;
      } else if (this.time == 60) {
        this.time = 59;
        this.times();
        sendSms({
          account: this.phone
        }).then(res => {
          if (res.code == 1) {
            this.checking1 = 2;
            this.$message({
              message: "发送成功",
              type: "success"
            });
          } else {
            this.$message.error("手机号格式不正确");
          }
        });
      }
    },
    times() {
      var Athis = this;
      var setTime = setTimeout(function() {
        if (Athis.time == 0) {
          clearTimeout(setTime);
          Athis.time = 60;
        } else {
          Athis.time--;
          Athis.times();
        }
      }, 1000);
    },
    delCard(v) {
      deletes({
        token: this.$token,
        id: v.id
      }).then(res => {
        if (res.code == 1) {
          this.cardArrs = [];
          this.$message.success("删除成功!");
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.addcard {
  padding-bottom: 100px;
  > div {
    margin-top: 26px;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    color: #4d4d4d;
    padding-left: 72px;
    position: relative;
  }
  button {
    width: 130px;
    height: 46px;
    background: rgba(78, 238, 200, 1);
    font-size: 18px;
  }
  .checking {
    input {
      width: 237px;
    }
  }
  p {
    position: absolute;
    top: 0;
    left: 500px;
    width: 160px;
    height: 46px;
    background: #e6e6e6;
    color: #808080;
    line-height: 46px;
    text-align: center;
  }
  input {
    position: absolute;
    text-indent: 10px;
    left: 185px;
    top: 0;
    width: 380px;
    height: 46px;
    border: 1px solid #e6e6e6 !important;
  }
}
.list {
  padding-left: 36px;
  padding-top: 36px;
  display: flex;
  > div {
    width: 400px;
    height: 224px;
    border: 3px solid rgba(78, 238, 200, 1);
    border-radius: 12px;
    padding-top: 45px;
    padding-left: 32px;
    padding-right: 32px;
    position: relative;
    margin-right: 60px;
  }
  .add {
    border: none;
    background: url("../../../../assets/image/wallet_Addbankcard@2x.png")
      no-repeat;
    background-size: 100%;
    p {
      text-align: center;
      color: #4eeec8;
      margin-top: 100px;
      cursor: pointer;
    }
  }
  .delect {
    position: absolute;
    display: block;
    top: 58px;
    right: 20px;
    color: #4eeec8;
    font-size: 20px;
    cursor:pointer;
  }
  .cardTitle {
    margin-bottom: 40px;
    height: 60px;
    line-height: 60px;
    span {
      margin-left: 32px;
      width: 66px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      display: inline-block;
      background: rgba(204, 204, 204, 1);
    }
  }
  .cardNum {
    text-align: center;
    font-size: 28px;
    span {
      margin-right: 6px;
    }
  }
  img {
    width: 187px;
    height: 46px;
    vertical-align: middle;
  }
}
.box {
  min-height: 600px;
}
.title {
  height: 69px;
  line-height: 69px;
  font-size: 18px;
  padding-left: 36px;
  border-bottom: 1px solid #e6e6e6;
}
</style>