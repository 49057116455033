<template>
  <div class="wrap bgw box">
    <div class="title">绑定支付宝</div>
    <div class="txt">
      <div>
        真实姓名：
        <input v-model="name" type="text" maxlength="22" />
      </div>
      <div>
        支付宝账号：
        <input v-model="num" type="text" maxlength="32" />
      </div>
      <button @click="checking()">绑定支付宝</button>
    </div>
  </div>
</template>
<script>
import { bindAlipay } from "@/request/user"; //榜单支付宝
export default {
  data() {
    return {
      name: "",
      num: ""
    };
  },
  created(){
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
  },
  methods: {
    checking() {
      if (!this.name) {
        this.$message.error("姓名不能为空");
      } else if (!this.num) {
        this.$message.error("账号不能为空");
      }else{
          this.send()
      }
    },
    send() {
      bindAlipay({
        token: this.$tokens,
        realname: this.name,
        account: this.num
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "绑定成功",
            type: "success"
          });
          this.$emit('index')
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.box {
  height: 600px;
}
.title {
  height: 69px;
  line-height: 69px;
  font-size: 18px;
  padding-left: 36px;
  border-bottom: 1px solid #e6e6e6;
}
.txt {
  padding-left: 72px;
  font-size: 18px;
  color: #4d4d4d;
  padding-top: 32px;
  > div {
    height: 46px;
    line-height: 46px;
    position: relative;
    margin-bottom: 26px;
  }
  input {
    position: absolute;
    top: 0;
    left: 120px;
    text-indent: 10px;
    height: 46px;
    width: 380px;
    border: 1px solid #e6e6e6 !important;
  }
  button {
    margin-top: 10px;
    width: 130px;
    height: 46px;
  }
}
</style>